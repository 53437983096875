<template>
  <b-sidebar :id="id" :title="title" right backdrop shadow>
    <form @submit.prevent="create" class="create-contact-field col mb-0">
        <!-- Name -->
        <div class="input-group">
            <label for="">{{$tc('generic-str.name', 1)}} <!-- <span class="material-symbols-outlined"> info </span> --></label>
            <input v-model="form.name" type="text" class="form-control" required placeholder="" />
        </div>

        <!-- Description -->
        <div class="input-group">
            <label for="">{{$t('departments.data-infos.lbl-description')}} <!-- <span class="material-symbols-outlined"> info </span> --></label>
            <textarea id="description-area" v-model="form.description" rows="4" class="form-control" placeholder=""/>
            <!-- <code>{{form.description}}</code> -->
        </div>

        <button v-b-toggle="id" class="btn btn-outline-primary">{{$t('generic-str.cancel')}}</button>
        <button :disabled="isSending" type="submit" :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" class="btn btn-primary ml-auto">{{$t('generic-str.create')}}</button>
    </form>
  </b-sidebar>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ContactService from '@/services/contact.service';
// import Modal from '@/mixins/Modal';

export default {
  // mixins: [Modal],
  props: ['id', 'title'],
  data() {
    return {
      form: {
        name: '',
        description: '',
      },
      isSending: false,
    };
  },
  methods: {
    create() {
      this.isSending = true;
      ContactService.createGroup(this.form).then(
        () => {
          // this.hide();
          this.toggleSidebar(this.id);
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('generic-str.created-group'),
            type: 'success',
          });
          this.$emit('sent');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
  },
};
</script>
<style scoped lang="scss">
/* Sidebar add prod */
::v-deep .b-sidebar {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .b-sidebar-header {
    padding-left: 2rem;
    padding-right: 2rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
  }
  header {
    >strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .create-contact-field {
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .custom-fields-group {
      span {
        font-weight: 400;
      }
      .toggle-custom {
        display: flex;
        align-items: center;
        width: 100%;
         .material-symbols-outlined {
          margin-left: auto;
          margin-right: 5px;
          transition: .5s;
          &.rotate {
            transform: rotate(-180deg);
          }
         }
      }
      li {
        display: flex;
        align-items: center;
        padding: 0 .5rem;
      }
      .delete-icon {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        color: #D5273E;
        margin-left: auto;
        font-size: 18px;
        transition: .5s;

        &:hover, &:focus {
          transform: scale(1.2);
        }
      }
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    .title-group {
      font-weight: 400;
      color: var(--gray-font-color-6);
      margin-bottom: 1rem;
    }
    .custom-field-list {
      width: 100%;
      li {
        margin-bottom: .5rem;
        color: var(--gray-font-color-2);
        font-weight: 400;
      }
    }
  }
}
</style>
