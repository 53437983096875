<template>
  <div class="content">
    <div class="row">
      <div class="col-12 col-sm">
        <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="$tc('sms.send-msg.tabs.contact.title', 2)" :subtitle="$t('contacts-component.configure')" />
        <!-- Search column -->
        <div class="col-8 mx-auto mb-5">
          <div class="form-wrapper">
            <form @submit.prevent="search" class="form-group col px-0 mb-0">
              <div class="input-group">
                <input
                  placeholder=""
                  type="text"
                  class="form-control"
                  :v-model="searchQuery"
                  ref="searchInput"
                />
                <div class="input-group-prepend">
                  <button @click="search" type="button" class="btn icon_btn m-0">
                    <span class="material-symbols-outlined"> search </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--/Search column -->
        <div class="page-content container">
          <div class="row row-options">
            <div class="control-group opt-2 d-flex align-items-center">
              <label for="customCheckGAll" class="control control-checkbox d-flex align-items-bottom mb-0">
                <input id="customCheckGAll" v-model="selectAllGroups" type="checkbox">
                <div class="control_indicator"></div>
                <span class="ml-2">{{$t('generic-str.all')}}</span>
              </label>
            </div>
            <button class="btn p-0 d-flex align-items-center" @click="deleteAll" v-bind:disabled="selectedGroups.length == 0">
              <!-- <span class="hide-m">{{$t('generic-str.delete')}}</span> -->
              <span class="material-symbols-outlined thin-icon">delete_forever</span>
              <span>{{$tc('app.selected', selectedGroups.length, {length: selectedGroups.length})}}</span>
            </button>
            <button class="btn btn-new-group" v-b-toggle.create-group>
              <span class="material-symbols-outlined thin-icon">add</span>
              <span>{{$t('generic-str.new-group')}}</span>
            </button>
          </div>
          <div class="row" v-if="fetched">
            <div class="content-row" v-for="(group, i) in groups" :key="group.id">
              <div class="col-12 d-flex">
                <div class="control-group opt-2">
                  <label :for="'group-check' + i" class="control control-checkbox">
                    <input :id="'group-check' + i" :value="group" v-model="selectedGroups" type="checkbox">
                    <div class="control_indicator"></div>
                  </label>
                </div>
                <span class="material-symbols-outlined thin-icon ml-2">group</span><p class="text-item">{{ group.name }}</p>
                <div class="option-wrapper thin-icon">
                  <a class="btn" v-modal="{ target: 'group-modal', data: group }"><span class="material-symbols-outlined"> edit </span></a>
                  <a class="btn" @click="remove(group.id)"><span class="material-symbols-outlined"> delete_forever </span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="loading min-h-300 scrollable-loading" v-else>
            <LoadingAnim />
          </div>
          <!-- <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button
                        v-modal="{ target: 'create-group' }"
                        class="btn btn-success btn-block"
                      >
                        <i class="icon dripicons-plus"></i
                        ><span class="hide-m"> {{$t('generic-str.new-group')}}</span>
                      </button>
                      <button
                        class="btn btn-danger btn-remove border-radius-right"
                        @click="deleteAll"
                        v-bind:disabled="selectedGroups.length == 0"
                      >
                        <i class="icon dripicons-trash color-white"></i
                        ><span class="hide-m">{{$t('generic-str.delete')}}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!groups.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i class="fas fa-users font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('contacts-component.none')}}</h5>
                  <p>{{$t('contacts-component.register')}}</p>
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="groups.length" class="bg-light">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAllGroups"
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheckGAll"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheckGAll"
                          ></label>
                        </div>
                      </th>
                      <th scope="col">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col">{{$tc('sms.send-msg.tabs.contact.title', 2)}}</th>
                      <th scope="col">{{$t('generic-str.info')}}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(group, i) in groups" :key="group.id">
                      <td data-label="">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectedGroups"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'group-check' + i"
                            :value="group"
                          />
                          <label
                            class="custom-control-label"
                            :for="'group-check' + i"
                          ></label>
                        </div>
                      </td>
                      <td data-label="Nome">{{ group.name }}</td>
                      <td data-label="Contatos">{{ group.contacts_count }}</td>
                      <td data-label="Informações">{{ group.description }}</td>
                      <td data-label="Nome">
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <a
                              class="dropdown-item"
                              v-modal="{ target: 'group-modal', data: group }"
                              >{{$t('generic-str.edit')}}</a
                            >
                            <a class="dropdown-item" @click="remove(group.id)"
                              >{{$t('generic-str.remove')}}</a
                            >
                            <router-link
                              class="dropdown-item"
                              :to="`groups/${group.id}`"
                              >{{$tc('sms.send-msg.tabs.contact.title', 2)}}
                            </router-link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div> -->
          <pagination :lastPage="pages" @change="fetch" />
        </div>
      </div>
    </div>
    <create-group-sidebar
      id="create-group"
      :title="$t('generic-str.new-group')"
      @sent="fetch(form.page)"
    ></create-group-sidebar>
    <group-modal id="group-modal" @sent="fetch(form.page)"></group-modal>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import ContactService from '@/services/contact.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/rebranding/Pagination.vue';
// import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import GroupModal from '@/components/rebranding/groups/GroupModal.vue';
import CreateGroupSidebar from '@/components/rebranding/groups/CreateGroupSidebar.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
    // CreateGroupModal,
    GroupModal,
    CreateGroupSidebar,
    LoadingAnim,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/contacts',
        2: '/contacts/groups',
      },
      fetched: false,
      searchQuery: null,
      groups: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      selectedGroups: [],
    };
  },
  computed: {
    selectAllGroups: {
      get() {
        if (this.groups) {
          return this.selectedGroups.length === this.groups.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.groups.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedGroups = selected;
      },
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    search() {
      this.fetched = false;
      console.info('Search function');
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      ContactService.getGroups(this.form).then(
        (response) => {
          this.fetched = true;
          this.groups = response.data;
          // console.log('Templates: ', this.groups);
          result = this.groups.filter((item) => word.toLowerCase().split(' ').every((v) => item.name.toLowerCase().includes(v)));
          // console.log('Return result: ', result);
          this.groups = result;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      ContactService.getGroups(this.form).then(
        (response) => {
          this.fetched = true;
          this.groups = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    contacts(g) {
      this.$router.push({ name: 'GroupContacts', params: { group: g } });
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('contact-list-component.groups.warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ContactService.deleteGroup(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.groups.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('contact-list-component.groups.warning-2'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const groupsToDelete = [];
          this.selectedGroups.forEach((group) => {
            groupsToDelete.push(group.id);
          });
          ContactService.deleteGroups({ ids: groupsToDelete }).then(
            () => {
              // this.hide();
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.groups.deleted-2'), 'success');
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
  },
  mounted() {
    this.$root.$on('groups.selected', this.selectedGroupsChange);
  },
};
</script>

<style scoped lang="scss">
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}

/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
.content-row {
  width: 100%;
  display: flex;
  background-color: var(--background-2);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  >div {
    display: flex;
    align-items: center;
    min-height: 52px;
  }
  /* @media (min-width: 768px) {
    div:last-child {
      justify-content: center;
    }
  } */
}
.control {
  margin-bottom: 0;
}
.control_indicator {
  top: 0;
}
.row-options {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
  gap: 1rem;
}

.option-wrapper {
  margin-left: auto;

  .btn {
    padding: 0;
    height: auto;
    color: var(--gray-font-color-4);
    &:first-of-type {
      margin-right: .5rem;
    }
  }
}
span, p {
  color: var(--gray-font-color-4);
  font-weight: 400;
}
.btn-new-group {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 400;
}
.text-item {
  margin-left: .5rem;
  margin-bottom: 0;
}
.thin-icon *, .thin-icon {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
</style>
