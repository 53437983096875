<template>
  <lottie-animation
    ref="anim"
    :animationData="require('@/assets/loading.json')"
    :loop="true"
    :autoPlay="true"
    :speed="1"
    class="svg_wrapper loading-icon-lottie"
  />
</template>
<script>
import LottieAnimation from 'lottie-web-vue';

export default {
  components: {
    LottieAnimation,
  },
};
</script>
<style scoped lang="scss">
.loading-icon-lottie {
  width: 44px;
  height: 44px;
  transform: scale(3);
  margin: auto;
}
</style>
