import $ from 'jquery';

const modal = {
  props: ['id'],
  data() {
    return {
      isOpening: false,
      isShow: false,
      isBlock: false,
      backdrop: null,
      data: null,
      form: {},
      initialData: {},
    };
  },
  computed: {
    modalId() {
      return this.id;
    },
    classes() {
      return [
        'modal',
        'fade',
        {
          show: this.isShow,
        },
      ];
    },
    styles() {
      return {
        display: this.isBlock ? 'block' : 'none',
      };
    },
  },
  mounted() {
    this.$root.$on('show.modal', this.showHandler);
    this.removeBackdrop();
  },
  destroy() {
    this.removeBackdrop();
  },
  methods: {
    show() {
      this.showBackdrop();
      //  document.getElementById('back').style.display = 'block';
      this.isBlock = true;
      setTimeout(() => {
        this.isShow = true;
      }, 150);
    },
    hide(reset = true, removeBackdrop = true) {
      this.isShow = false;
      this.isBlock = false;
      if (removeBackdrop) {
        this.removeBackdrop();
      }
      if (reset) {
        this.form = this.initialData;
      }
    },
    showBackdrop() {
      console.log('showBackdrop');
      const modalBackdrops = document.getElementsByClassName('modal-backdrop');

      if (modalBackdrops.length) {
        console.log(2222222222222222, modalBackdrops, modalBackdrops.length);
        modalBackdrops.forEach((backdrop) => {
          backdrop.remove();
        });
      }

      if (!$('body').hasClass('modal-backdrop')) {
        this.backdrop = document.createElement('div');
        this.backdrop.className = 'modal-backdrop fade show';

        document.body.appendChild(this.backdrop);
        setTimeout(() => {
          // this.backdrop.classList.add('show');
        }, 150);
      } else {
        console.log('have');
        $('.modal-backdrop').addClass('show');
      }
      $('body').addClass('modal-open');
    },
    removeBackdrop() {
      if (this.backdrop) {
        this.backdrop.classList.remove('show');
      }
      $('body .modal-backdrop').remove();
      $('.modal-backdrop').removeClass('show');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      if (this.backdrop) {
        setTimeout(() => {
          this.backdrop.remove();
          this.backdrop = null;
        }, 150);
      }
    },

    showHandler(id, data) {
      if (id === this.modalId) {
        this.data = data;
        this.initialData = JSON.parse(JSON.stringify(this.form));
        if (data) {
          this.form = JSON.parse(JSON.stringify(data));
        }
        this.isOpening = true;
        this.show();
      }
    },
  },
};

export default modal;
