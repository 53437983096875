import axios from '@/plugins/axios';

class ContactService {
  getContacts(data) {
    this.data = data;
    return axios().get('contacts', { params: this.data })
      .then((response) => response.data);
  }

  getByGroups(data) {
    this.data = data;
    return axios().post('contacts/by-groups', this.data)
      .then((response) => response.data);
  }

  searchContacts(data, name) {
    this.data = data;
    return axios().get('contacts', { params: this.data })
      .then((response) => response.data);
  }

  createContact(data) {
    this.data = data;
    return axios().post('contacts', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  importContact(data) {
    this.data = data;
    return axios().post('contacts/imports', this.data).then((response) => response.data);
  }

  deleteContacts(data) {
    this.data = data;
    return axios().delete('contacts', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  deleteGroups(data) {
    this.data = data;
    return axios().delete('contacts/groups', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  /* importContactFile(data) {
    this.data = data;
    return axios().post('contacts/imports', this.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response.data);
  } */

  importContactFile(data) {
    // const bar = document.getElementById('progressBar');
    const bar = document.querySelector('#progressBar .progress-bar');
    const circularProgress = document.querySelector('.circular-progress');
    const progressValue = document.querySelector('.progress-value');
    const labelPercent = document.getElementById('percentage');
    this.data = data;
    return axios().post('contacts/imports', this.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressValue.textContent = `${percentCompleted}%`;
        circularProgress.style.background = `conic-gradient(#752de6 ${percentCompleted * 3.6}deg, #ededed 0deg)`;
        // bar.setAttribute('value', percentCompleted);
        bar.style.width = `${percentCompleted}%`;
        labelPercent.innerHTML = `${percentCompleted}%`;
        console.log(`Progress: ${percentCompleted}%`);
      },
    }).then((response) => response.data);
  }

  importContactFinish(idImport, data) {
    this.data = data;
    return axios().put(`contacts/imports/${idImport}`, this.data).then((response) => response.data);
  }

  getImports(data) {
    this.data = data;
    return axios().get('contacts/imports', { params: this.data }).then((response) => response.data);
  }

  editContact(data) {
    this.data = data;
    return axios().put(`contacts/${data.id}`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  editImage(data) {
    this.data = data;
    return axios().put(`contacts/profile-image/${data.id}`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  editList(data) {
    this.data = data;
    return axios().put('contacts', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteContact(id) {
    this.id = id;
    return axios().delete(`contacts/${id}`).then((response) => response.data);
  }

  getGroups(data) {
    this.data = data;
    return axios().get('contacts/groups', { params: this.data }).then((response) => response.data);
  }

  getGroup(id, data) {
    this.id = id;
    return axios().get(`contacts/groups/${id}`, { params: data }).then((response) => response.data);
  }

  deleteGroupContact(groupId, contactId) {
    this.groupId = groupId;
    return axios().delete(`contacts/groups/${groupId}/contacts/${contactId}`).then((response) => response.data);
  }

  deleteGroupContacts(attrs, groupId) {
    this.attrs = attrs;
    return axios().delete(`contacts/groups/${groupId}/contacts/`, { data: attrs }).then((response) => response.data);
  }

  getContactsByGroup(group) {
    this.data = 'a';
    return axios().get(`contacts?group=${group}`);
  }

  createGroup(data) {
    this.data = data;
    return axios().post('contacts/groups', this.data).then((response) => response.data);
  }

  editGroup(data) {
    this.data = data;
    return axios().put(`contacts/groups/${data.id}`, this.data).then((response) => response.data);
  }

  deleteGroup(id) {
    this.id = id;
    return axios().delete(`contacts/groups/${id}`).then((response) => response.data);
  }
}

export default new ContactService();
